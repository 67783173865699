.s1hrux09{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:calc(100% - 48px);height:calc(100% - 48px);margin:24px;gap:26px;}
.h27jykk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;width:100%;height:32px;margin-bottom:24px;}
.b1voj2g9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;height:100%;}
.pob7zvp{overflow:hidden;padding-bottom:4px;height:calc(100% - var(--pob7zvp-0));}
.phf9ldl{width:100%;height:100%;}
.ck593jy{position:relative;background:#ffffff;box-shadow:0px 0px 4px rgba(0,0,0,0.25);border-radius:8px;margin:2px;height:100%;min-height:100%;}
.c6g97pt{border-bottom:1px solid #dbdbdb;}
.c122bsdg{padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;}
.cnyymmh{-webkit-flex:0 0 1px;-ms-flex:0 0 1px;flex:0 0 1px;height:24px;background-color:#dbdbdb;}
.chiadi2{position:relative;padding:16px;height:var(--chiadi2-0);overflow:hidden;}
.iwllfbt{padding:var(--iwllfbt-0);}
.fcvxrif{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:var(--fcvxrif-0);}
.s1s7yjfd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:var(--s1s7yjfd-0);gap:8px;position:-webkit-sticky;position:-webkit-sticky;position:sticky;top:0;background-color:#fff;border-bottom:1px solid #dbdbdb;z-index:4;}
.i1epf6ti{cursor:pointer;}
.h18kmbrh{width:100%;height:100%;overflow:hidden;}
.s13i854i{height:calc(100vh - var(--s13i854i-0));overflow:auto;}
