.s15qzowi{min-width:var(--s15qzowi-0);overflow-y:auto;padding:20px 0px;background-color:#f8feff;box-shadow:2px 0px 2px rgba(0,0,0,0.16);}
.soww4jh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:8px;width:56px;box-sizing:border-box;}
.s346wib{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:40px;background-color:var(--s346wib-0);color:var(--s346wib-1);cursor:var(--s346wib-2);box-sizing:border-box;}.s346wib:hover{background-color:var(--s346wib-3);color:var(--s346wib-4);}
.s1gt71b6.s346wib{-webkit-box-pack:center;-webkit-justify-contents:center;-ms-flex-pack:center;justify-contents:center;padding:8px 16px;color:var(--s1gt71b6-0);}
.s177usn{width:240px;box-sizing:border-box;}
.s2kt57g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:8px;box-sizing:border-box;}
.sesq3iy{width:100%;}
.s1x9b3t8.s346wib{padding:0px 16px;gap:8px;}
.sw3xn59{position:relative;}
.s168k0tj{position:absolute;left:-4px;top:-10px;}
.s7ljf1v{-webkit-flex:auto;-ms-flex:auto;flex:auto;}.s7ljf1v span{vertical-align:super;}
.s1wwjk04{-webkit-transition:height 0.2s linear,opacity 0.2s ease-in;transition:height 0.2s linear,opacity 0.2s ease-in;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:4px;}
.s1h86gyo{height:32px;padding:4px 0 4px 48px;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--s1h86gyo-0);color:var(--s1h86gyo-1);cursor:var(--s1h86gyo-2);box-sizing:border-box;}.s1h86gyo span{color:var(--s1h86gyo-3) !important;}.s1h86gyo:hover{background-color:var(--s1h86gyo-4);}.s1h86gyo:hover span{color:var(--s1h86gyo-5) !important;}
.s1qbdu9s{width:4px;height:32px;position:absolute;left:0;top:0;background:#008599;}
.s1chy2qn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;left:26px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
